import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { AiOutlineClose } from "react-icons/ai";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ReactNode, useEffect } from "react";
import { SxProps } from "@mui/material";

interface FullScreenDialogProps {
    modalShow: boolean;
    onClose: (status: boolean) => void;
    childComponent: ReactNode;
    customStyles?: SxProps;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: React.FC<FullScreenDialogProps> = ({
    modalShow,
    onClose,
    childComponent,
    customStyles,
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = (): void => {
        setOpen(false);
        onClose(false);
    };

    useEffect(() => {
        if (modalShow) {
            setOpen(true);
        }
    });

    return (
        <div>
            <Dialog
                id="historical-chart-id"
                keepMounted={false}
                fullScreen
                open={open}
                // onClose={() => onClose(false)}
                TransitionComponent={Transition}
                className="full-width-dialog-wrapper"
                sx={customStyles}
            >
                <IconButton
                    id="fullscreen-close-icon-id"
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    className="close-btn"
                >
                    <AiOutlineClose />
                </IconButton>
                {childComponent}
            </Dialog>
        </div>
    );
};

export default FullScreenDialog;
