import { useEffect, useRef, useState } from "react";
import SectionHeading from "components/headings/SectionHeading";
import { AiFillAppstore } from "react-icons/ai";
import PaperWrapper from "components/wrappers/PaperWrapper";
import CommonTable from "components/tables/CommonTable";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import TableHeading from "components/headings/TableHeading";
import RegionDetailCard from "components/cards/RegionDetailCard";
import Map from "features/app-taxi-ranking/map/Map";
import { useParams, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ZoneDetailCard from "components/cards/ZoneDetailCard";
import { CLASS_INCIDENTS, CLASS_TAXIES, CLASS_EMBARK_PASSENGERS } from "constants/class.constants";
import { executeGetDetectionsByLocationsTaxiRequest } from "api/common/detections.api";
import { Link } from "react-router-dom";
import StatisticsZoneLocationCards from "features/app-taxi-ranking/statistics/StatisticsZoneLocationCards";
import { Location } from "reducers/newLocation.reducer";
import { buildLocationAddressStr } from "utils/build-location-str";
import { Helmet } from "react-helmet";
import { AppTitlePrefix } from "constants/app.constants";
import IotVisionSelectFiled from "components/common/IotVisionSelectField";
import { executeGetIncidentsByLocationsTaxiRequest } from "api/app-taxi-ranking/taxi-incidents.api";
import { DetectionsByLocationQuery, IByLocation, LocationDetection } from "types";
import IotVisionToolTips from "components/common/IotVisionToolTips";
import HeaderDatePicker from "features/common/date-header/HeaderDatePicker";
import { FetchFunctionParams, makeApiCallWithUpdateTime } from "utils/common";
import { useAppDispatch } from "store";
import { findLocation } from "utils/search-tree";
import { getChildrenCounts } from "utils/get-children-count-in-tree";
import useDataFilterByScope from "hooks/useDataFilterByScope";
import { SCOPES } from "enums/scopes.enum";
import { ITopLocationParams } from "../dashboard";
import NswLogo from "../../../assets/images/nsw-logo.png";

interface FetchDataParamType extends FetchFunctionParams {
    level: number;
    startTime: string;
    endTime: string;
}

const options = [
    {
        scope: "",
        value: CLASS_EMBARK_PASSENGERS,
        title: "Highest Passengers",
    },
    {
        scope: SCOPES.READ_INCIDENTS,
        value: CLASS_INCIDENTS,
        title: "Highest Incidents",
    },
    {
        scope: "",
        value: CLASS_TAXIES,
        title: "Highest Taxis",
    },
];

const HomeRegionPage = (): JSX.Element => {
    const incidentAbortControllerRef = useRef<AbortController | null>(null);
    const detectionAbortControllerRef = useRef<AbortController | null>(null);
    const [gridData, setGridData] = useState<LocationDetection[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>();
    const [parent, setParent] = useState<Location | undefined>();
    const [childrenCount, setChildrenCount] = useState<number>(0);
    const { locationId } = useParams();
    const { startTime, endTime, selectedRange } = useSelector((state) => state.time);
    const [filterClass, setFilterClass] = useState<string>(CLASS_EMBARK_PASSENGERS);
    const { locationsTree } = useSelector((state) => state.newLocation);
    const { appCode } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const filteredDataArray = useDataFilterByScope([SCOPES.READ_INCIDENTS], options);

    const columns: GridColumns = [
        {
            field: "locationName",
            headerName: "Location",
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.locationName} arrow>
                    <span> {params.row.locationName}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "groupName",
            headerName: "LGA",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <IotVisionToolTips title={params.row.groupName} arrow>
                    <span> {params.row.groupName}</span>
                </IotVisionToolTips>
            ),
        },
        {
            field: "location",
            headerName: "",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`/${appCode}/home/location/${params.row.id}`}>{params.value}</Link>
            ),
            align: "right",
        },
    ];
    const pageSize = 5;
    const rowsPerPageOptions = [5, 10];
    const hideFooterPagination = true;

    const fetchData = async ({ startTime, endTime, level }: FetchDataParamType) => {
        if (filterClass === CLASS_INCIDENTS) {
            await getIncidentsByLocationsDetections({
                fromTime: startTime,
                toTime: endTime,
                locationId,
                level,
            });
        } else {
            await getDetectionsByLocationsDetections({
                fromTime: startTime,
                toTime: endTime,
                classification: filterClass,
                locationId,
            });
        }
    };

    async function getDetectionsByLocationsDetections(data?: DetectionsByLocationQuery) {
        try {
            detectionAbortControllerRef.current = new AbortController();
            const { signal } = detectionAbortControllerRef.current;
            const response = await executeGetDetectionsByLocationsTaxiRequest(data, { signal });

            if (response && Array.isArray(response)) {
                processGridData(response);
            }
        } catch (e) {
        } finally {
            // setLoading(false);
        }
    }

    async function getIncidentsByLocationsDetections(data?: ITopLocationParams) {
        try {
            incidentAbortControllerRef.current = new AbortController();
            const { signal } = incidentAbortControllerRef.current;
            const response = await executeGetIncidentsByLocationsTaxiRequest(data, { signal });

            if (response && Array.isArray(response)) {
                processGridData(response);
            }
        } catch (e) {}
    }

    function processGridData(responseData: IByLocation[]) {
        const processedLocationDetections: LocationDetection[] = [];
        responseData
            .sort((a, b) => b.count - a.count)
            .slice(0, 5)
            .forEach((detection) => {
                const location = findLocation(locationsTree, detection.locationId);
                let group!: Location | undefined;
                if (location && location.parentId) {
                    group = findLocation(locationsTree, location?.parentId ?? "");
                }
                if (location) {
                    processedLocationDetections.push({
                        ...location,
                        locationName: location?.name,
                        address: buildLocationAddressStr(location),
                        location: "View Location",
                        groupName: group?.name,
                    });
                }
            });
        setGridData(processedLocationDetections);
    }

    const handleChange = (v: SelectChangeEvent<unknown>) => {
        setFilterClass(v.target.value as string);
    };

    useEffect(() => {
        let selected!: Location | undefined;
        if (locationId) {
            selected = findLocation(locationsTree, locationId);
        }
        setSelectedLocation(selected);

        if (selected) {
            const parentId = selected?.parentId;
            let parent: Location | undefined;
            if (parentId) {
                parent = findLocation(locationsTree, parentId);
            }
            setSelectedLocation(selected);
            setParent(parent);

            const childrenDetails = getChildrenCounts([selected]);
            setChildrenCount(childrenDetails[selected.id]);
        }

        if (!selected) {
            navigate(`/${appCode}/page-not-found`, { replace: true });
        } else {
            let level = 0;
            if (selected?.type === "region") {
                level = 1;
            } else if (selected?.type === "zone") {
                level = 2;
            }

            makeApiCallWithUpdateTime(selectedRange, { startTime, endTime, level }, fetchData, dispatch);
            return () => {
                if (filterClass === CLASS_INCIDENTS) {
                    incidentAbortControllerRef.current?.abort();
                } else {
                    detectionAbortControllerRef.current?.abort();
                }
            };
        }
    }, [locationId, startTime, endTime, filterClass]);

    return (
        <Grid container spacing={1}>
            {selectedLocation && selectedLocation.type === "region" && (
                <>
                    <Helmet>
                        <title>{AppTitlePrefix} | Region Summary</title>
                        <link rel="icon" id="tr-region" type="image/png" href={NswLogo} sizes="32x32" />
                    </Helmet>
                    <Grid item xs={12}>
                        <RegionDetailCard region={selectedLocation.name} noOfLga={childrenCount} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                        <SectionHeading heading="Region Summary" icon={<AiFillAppstore className="icon" />} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                        <div
                            style={{
                                maxWidth: "300px",
                                marginLeft: "auto",
                            }}
                        >
                            <HeaderDatePicker />
                        </div>
                    </Grid>
                </>
            )}
            {selectedLocation && selectedLocation.type === "zone" && (
                <>
                    <Helmet>
                        <title>{AppTitlePrefix} | Zone Summary</title>
                    </Helmet>
                    <Grid item xs={12}>
                        <ZoneDetailCard
                            regionType={parent?.name || ""}
                            region={selectedLocation.name}
                            noOfLocations={childrenCount}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                        <SectionHeading heading="Zone Summary" icon={<AiFillAppstore className="icon" />} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                        <div
                            style={{
                                maxWidth: "300px",
                                marginLeft: "auto",
                            }}
                        >
                            <HeaderDatePicker />
                        </div>
                    </Grid>
                </>
            )}
            {selectedLocation?.id === locationId && (
                <Grid item xs={12}>
                    <StatisticsZoneLocationCards group={selectedLocation} />
                </Grid>
            )}
            <Grid item xs={12}>
                <PaperWrapper>
                    <Map id={locationId} />
                </PaperWrapper>
            </Grid>
            <Grid item xs={12} sm={12}>
                <PaperWrapper>
                    <Grid container spacing={1}>
                        <Grid item xs={9} sm={9} md={8} lg={9} xl={10}>
                            <TableHeading heading="Top Locations" />
                        </Grid>
                        <Grid item xs={3} sm={3} md={4} lg={3} xl={2}>
                            <IotVisionSelectFiled fullWidth value={filterClass} onChange={handleChange}>
                                {filteredDataArray?.map((option: { value: string; title: string }) => {
                                    return (
                                        <MenuItem key={option?.value} value={option?.value}>
                                            {option?.title}
                                        </MenuItem>
                                    );
                                })}
                            </IotVisionSelectFiled>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CommonTable
                                columns={columns}
                                rows={gridData}
                                pageSize={pageSize}
                                rowsPerPageOptions={rowsPerPageOptions}
                                hideFooterPagination={hideFooterPagination}
                            />
                        </Grid>
                    </Grid>
                </PaperWrapper>
            </Grid>
        </Grid>
    );
};

export default HomeRegionPage;
