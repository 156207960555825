export enum SCOPES {
    UPDATE_INCIDENTS_STATUS = "update:incidents:status",
    VIEW_INCIDENTS_PAGE = "view:incidents:page",
    VIEW_EVENTS_PAGE = "view:events:page",
    READ_REPORTS = "read:reports",
    READ_SUMMARY_REPORTS = "read:reports:SUMMARY_REPORT",
    READ_WORKFLOW_REPORTS = "read:reports:WORK_FLOW_MANAGEMENT_SUMMARY_REPORT",
    VIEW_AUDIT_LOGS_PAGE = "view:audit-logs:page",
    READ_METADATA_SEARCH_REPORTS = "read:reports:METADATA_SEARCH_REPORT",
    DOWNLOAD_METADATA_SEARCH_REPORTS = "download:reports:METADATA_SEARCH_REPORT",
    CREATE_METADATA_SEARCH_REPORT = "create:reports:METADATA_SEARCH_REPORT",
    VIEW_FILES_INCIDENTS = "view:files:incidents",
    DOWNLOAD_FILES_INCIDENTS = "download:files:incidents",
    READ_FILES_INCIDENTS = "read:files:incidents",
    CREATE_INCIDENTS_VIDEO = "create:incidents:video",
    VIEW_VIDEO_REQUESTS_PAGE = "view:video-requests:page",
    VIEW_CREATE_VIDEO_REQUESTS_PAGE = "view:create-video-requests:page",
    READ_INCIDENTS = "read:incidents",
    MANUAL_VIDEO_REQUEST_ADMIN_SCOPE = "read:incidents:MANUAL_VIDEO_REQUEST:*",
    READ_INCIDENT_TRENDS_REPORTS = "read:reports:INCIDENT_TRENDS_REPORT",
    READ_DEVICE_VIDEO = "read:device:video",
    VIEW_CAMERA_POSITION = "view:camera-comparison:page",
    UPDATE_DEVICE_SNAPSHOT = "update:device:snapshot",
}
